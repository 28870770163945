html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Alata', "Segoe UI", Calibri, Futura, verdana, sans-serif;
  font-size: 21px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
h1 {
  color: #363636;
  background: #ffbcbc;
}
.page {
  background: #363636;
}
.screen {
  background: #4cd3c2;
  color: #363636;
}
.footer {
  background: #b7efcd;
  color: #363636;
}
.container {
  margin: 0 auto;
  max-width: 960px;
}
h1 {
  font-family: 'Saira Stencil One', cursive;
  margin: 0;
  font-size: 3em;
  text-align: center;
  font-weight: 800;
  letter-spacing: 0.2em;
  user-select: none;
  cursor: default;
}
h1:hover {
  text-shadow: none;
}
.caption {
  text-align: center;
  color: #ffbcbc;
  font-size: 2em;
}
.statusbar {
  display: flex;
  flex-direction: row;
  font-family: arial;
  color: rgba(255,255,255,0.4);
  padding: 0.5em 1em;
  text-align: right;
  font-size: 0.85em;
}
.statusbar .status {
  flex: 1;
  text-align: left;
}
.statusbar .credits {
  flex: 1;
  text-align: right;
}
.statusbar .credits a {
  color: rgba(255,255,255,0.4);
  text-decoration: none;
}
.statusbar .credits a:hover {
  color: rgba(255,255,255,0.7);
}
.page {
  padding: 1.5em;
  flex: 1;
}
.stats {
  color: #ffbcbc;
  text-align: center;
  margin-bottom: 0.5em;
}
.badge {
  display: inline-block;
  margin-left: 0.5em;
  padding: 0.25em;
  border-radius: 0.25em;
  background: #ffbcbc;
  color: #363636;
  min-width: 8em;
  text-align: right;
  transition: opacity 0.5s linear;
}
.screen {
  padding: 1em;
  text-align: justify;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.55);
  overflow-y: auto;
  min-height: 30vh;
  max-height: 50vh;
  user-select: none;
}
.screen em {
  font-size: 1.2em;
  line-height: 1.4em;
  font-style: normal;
  font-weight: normal;
  transition: all 0.3s ease;
}
.screen em.ok {
  background: rgba(0,0,0,0.25);
  transition: all 0.3s ease;
}
.writebox {
  opacity: 0;
  position: absolute;
  left: -9999px;
}
.footer {
  font-family: 'Alata', cursive;
  font-size: 0.85em;
  text-align: center;
  padding: 0.5em;
}
.subfooter {
  background: #ffbd69;
  color: #202040;
  text-align: center;
  padding-bottom: 3em;
}
.p-row {
  display: flex;
}
.p-row p {
  font-size: 0.75em;
  text-align: justify;
  flex: 1;
  margin: 0 1em;
}
